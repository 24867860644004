.device-card {
  background: linear-gradient(
    155deg,
    rgba(246, 240, 225, 0.642),
    rgba(255, 255, 255, 0.9) 80%
  );
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 200px;
  width: 100%;
  cursor: pointer;
  padding: 16px;
  box-shadow: 0px 3px 0px rgba(0, 0, 0, 0.2);
  transition: all 0.2s ease;
}

.device-card:hover {
  transform: scale(1.05);
  border: none;
  box-shadow: 0px 5px 10px rgba(255, 255, 255, 0.1);
}

.device-card[disabled] {
  cursor: default;
}

.device-card-group {
  position: absolute;
  right: 8px;
  top: -12px;
  background-color: white;
  border: 1px solid #d1d5db;
  border-radius: 6px;
  padding: 4px 8px;
  font-size: 12px;
  font-weight: 600;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
}

.device-card-info {
  padding: 8px 0;
  color: #4b5563;
  font-size: 14px;
}

.device-card-buttons-section {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-top: 16px;
}

.device-card-button {
  width: 100%;
  padding: 8px 12px;
  border: 1px solid #d1d5db;
  border-radius: 6px;
  background-color: white;
  color: #1f2937;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.device-card-button:hover {
  background-color: #f3f4f6;
}
