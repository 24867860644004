.PopupUI {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-y: auto;
}

.PopupUI-Block {
  position: relative;
  max-width: 1280px;
  width: 100%;
  background-color: white;
  border-radius: 0;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 20px;
  margin: auto;
}

.PopupUI-Main {
  padding: 20px;
}

.PopupUI-Main::-webkit-scrollbar {
  width: 8px;
}

.PopupUI-Main::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.PopupUI-Main::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 4px;
}

.PopupUI-Main::-webkit-scrollbar-thumb:hover {
  background: #555;
}

@media screen and (max-width: 1280px) {
  .PopupUI-Block {
    display: flex;
    min-width: 100%;
    min-height: 100%;
    flex-direction: column;
  }
}
